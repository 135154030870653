footer {
  text-align: center;
  color: white;
  margin-top: 1rem;

  a {
    color: white;
    .footerLogo {
      margin: 0 0.5rem;
      height: 1.5rem;
      border-radius: 50%;
      border: 1px solid white;
    }
    &:hover {
      text-decoration: underline;
      color: white !important;
    }
  }

  &.light {
    color: black;
    a {
      color: black;
      &:hover {
        color: black !important;
      }
    }
  }
}
