.Layout {
  .navbar {
    color: white;
    a {
      color: white;
    }
    .nav-link {
      color: white !important;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
