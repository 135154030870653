.Create {
  .btn {
    margin-right: 1rem;
  }

  .petal {
    cursor: pointer;

    &:hover {
      opacity: 75%;
    }
  }

  svg {
    cursor: pointer;
    margin-left: -1rem; // HACK WARNING THE SVG ISNT CENTERED IN ITS CONTAINER
    width: 50vh;
  }

  .buttonRow {
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .right-button {
    text-align: right;
  }
}
